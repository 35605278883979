
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import { defineComponent } from "vue";
import { setPageTitle } from "@/core/helpers/dom";

export default defineComponent({
  name: "CustomersResourcePage",
  components: { ResourcePage },
  setup() {
    setPageTitle("Customers");
  },
});
